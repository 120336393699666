.restaurant {
  height: 8rem;
  font-size: 1rem;
  line-height: 1.7rem;
  margin: 1rem 0;
}

.restaurant-name {
  font-size: 2rem;
}

.restaurant-kids {
  margin-top: 1rem;
}
