body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:hover,
a:focus {
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.button:focus {
  outline: none;
}

.subtle {
  color: #777;
}

.splash {
  text-shadow: -2px 2px 3px #ff2d95;
}

.flash {
  animation-name: flash;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

@keyframes flash {
  0% { background-color: unset; } 
  50% { background-color: #fff; } 
  100% { background-color: unset; } 
}
